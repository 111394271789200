<template>
  <!-- Begin Page Content -->
  <div class="container">

    <!-- Outer Row -->
    <div class="row justify-content-center">

      <div class="col-xl-10 col-lg-12 col-md-9">

        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                  </div>
                  <form class="user">
                    <!-- <div class="form-group">
                                    <input type="email" class="form-control form-control-user"
                                        id="exampleInputEmail" aria-describedby="emailHelp"
                                        placeholder="Enter Email Address...">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control form-control-user"
                                        id="exampleInputPassword" placeholder="Password">
                                </div>
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox small">
                                        <input type="checkbox" class="custom-control-input" id="customCheck">
                                        <label class="custom-control-label" for="customCheck">Remember
                                            Me</label>
                                    </div>
                                </div>
                                <a href="index.html" class="btn btn-primary btn-user btn-block">
                                    Login
                                </a> -->
                    <hr>
                    <button id="googleSignIn" class="btn btn-google btn-user btn-block">
                      <i class="fab fa-google fa-fw"></i> Login with Google
                    </button>
                    <!-- <a href="index.html" class="btn btn-facebook btn-user btn-block">
                                    <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                </a> -->
                  </form>
                  <hr>
                  <!-- <div class="text-center">
                                <a class="small" href="forgot-password.html">Forgot Password?</a>
                            </div>
                            <div class="text-center">
                                <a class="small" href="register.html">Create an Account!</a>
                            </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
  <!-- End of Main Content -->
</template>
  
<script>
import { app, auth, GoogleAuthProvider, signInWithPopup, signOut } from '../../firebaseInit';

export default {
  name: 'SignIn',
  mounted() {
    // const auth = getAuth();
    const googleSignInButton = document.getElementById('googleSignIn');

    googleSignInButton.onclick = (event) => {
      event.preventDefault();
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var credential = GoogleAuthProvider.credentialFromResult(result);
          var token = credential.accessToken;
          // The signed-in user info.
          var user = result.user;

          this.$router.push('/'); // Redirect to '/' route
          console.log(app.name);
          console.log(user.email);

          if (!user.email.endsWith('@aip.media')) {
            alert('Access is restricted to @aip.media accounts only.');
            signOut(auth);
            return;
          }
          
        }).catch((error) => {
          console.error('Sign in error', error);
        });
    }
  }
}
</script>
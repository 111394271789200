<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Reporting Assistant</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo">
        </div>
      </div>
      <div id="query-form">

        <h2>Select dates</h2>
        <div class="date-picker-group container-columns">
          <div class="col-flex-left">
            <h3>Report date range</h3>
            <select id="predefined-dates" style="display:none">
              <option value="">Select a date range...</option>
              <option value="last7">Last 7 Days</option>
              <option value="lastWeek">Last Week (Mon-Sun)</option>
              <option value="lastMonth">Last Month</option>
              <option value="thisMonth">This Month</option>
            </select>
            <div class="container-columns">
              <div class="col-flex-left">
                <label for="startDate">Start Date:</label>
                <input type="date" id="startDate">
              </div>
              <div class="col-flex-right">
                <label for="endDate">End Date:</label>
                <input type="date" id="endDate">
              </div>
            </div>
          </div>
          <div class="col-flex-right">
            <h3>Comparison date range</h3>
            <select id="predefined-dates-comparison" style="display:none">
              <option value="">Select a comparison range...</option>
              <option value="last7">Previous Period</option>
              <option value="lastWeek">Previous Year</option>
            </select>
            <div class="container-columns">
              <div class="col-flex-left">
                <label for="compareStartDate">Start Date:</label>
                <input type="date" id="compareStartDate">
              </div>
              <div class="col-flex-right">
                <label for="compareEndDate">End Date:</label>
                <input type="date" id="compareEndDate">
              </div>
            </div>
          </div>
        </div>


        <div class="container-columns" style="margin-top: 20px;">
          <div class="col-flex-2">
            <h2>Client</h2>
            <select id="client">
              <option value="" disabled selected>Select a client...</option>
              <!-- Clients will be dynamically populated here -->
            </select>

            <h2>Platforms</h2>
            <!-- Button to open the modal -->
            <button id="platforms-btn" disabled> Select Platforms </button>
            <div id="selected-platforms">No platforms selected</div>

            <h2>Campaigns</h2>
            <!-- Button to open the modal -->
            <button id="campaigns-btn" disabled> Select Campaigns </button>
            <div id="selected-campaigns">No campaigns selected</div>
          </div>

          <div class="col-flex-1">
            <h2>Core Campaign KPI</h2>
            <div id="core-campaign-kpi" class="radio-button-group">
              <div class="radio-item" style="display:none">
                <input type="radio" name="kpi" value="revenue" id="kpi-revenue">
                <label for="kpi-revenue">Revenue</label>
              </div>
              <div class="radio-item" style="display:none">
                <input type="radio" name="kpi" value="roas" id="kpi-roas">
                <label for="kpi-roas">ROAS</label>
              </div>
              <div class="radio-item">
                <input type="radio" name="kpi" value="cpa" id="kpi-cpa">
                <label for="kpi-cpa">CPA</label>
              </div>
              <div class="radio-item">
                <input type="radio" name="kpi" value="conversions" id="kpi-conversions">
                <label for="kpi-conversions">Conversions</label>
              </div>
              <div class="radio-item">
                <input type="radio" name="kpi" value="clicks" id="kpi-clicks">
                <label for="kpi-clicks">Clicks</label>
              </div>
            </div>
          </div>
        </div>


        <!-- Platforms Modal -->
        <div id="platforms-modal" class="modal-comms">

          <!-- Modal content -->
          <div class="modal-content-comms">
            <h2>Select Platforms</h2>
            <div id="platforms" class="checkbox-container">
              <!-- Checkboxes will be inserted here -->
            </div>
            <button id="select-platforms-btn">Select</button>
          </div>

        </div>

        <!-- Campaigns Modal -->
        <div id="campaigns-modal" class="modal-comms">

          <!-- Modal content -->
          <div class="modal-content-comms">
            <h2>Select Campaigns</h2>
            <div id="campaigns" class="checkbox-container">
              <!-- Checkboxes will be inserted here -->
            </div>
            <button id="select-campaigns-btn">Select</button>
          </div>

        </div>

        <div class="commentary-button">
          <button id="comms-button" type="submit">Generate report</button>
        </div>
      </div>
      <div id="loading" class="loading" style="display: none;">
        <div class="spinner"></div>
      </div>
      <div id="results">
        <!-- Query results will be displayed here -->
      </div>
    </div>

  </div>
  <!-- End of Main Content -->
</template>

<script>
export default {
  name: 'ReportCommentary',
  mounted() {
    import("../../../assets/js/reports.js").then(m => {
      m.report;
    });
  }
}
</script>

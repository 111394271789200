import { app, auth } from './firebaseInit';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

let appInstance = null;

auth.onAuthStateChanged(user => {
  if (!appInstance) {
    appInstance = createApp(App)
      .use(router)
      .mount('#app');
  }
});

<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Manage Vector Database</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo">
        </div>
      </div>
      <div id="content-main">

        <div class="header-section">
          <h2>{{ className }}</h2>
          <button @click="openClassModal" class="add-data-button"><i class="fas fa-fw fa-upload"></i> Add Data</button>
        </div>
        <DataTable v-if="columns.length" :columns="columns" :data="data" :options="{
          createdRow: (row, data) => {
            const cells = row.getElementsByTagName('td');
            Array.from(cells).forEach((cell, i) => {
              cell.classList.add('truncate');
              cells[i].title = cells[i].innerText;
            });

            // Setup event listeners
            row.querySelector('.edit-btn').addEventListener('click', () => {
              editRecord(data);
            });
            row.querySelector('.delete-btn').addEventListener('click', () => {
              deleteRecord(data);
            });
          }
        }" class="display" scrollX: true>
        </DataTable>
      </div>
    </div>
  </div>
  <PropertyModal ref="propertyModal" />
  <!-- End of Page Content -->
</template>


<script>
import { getClassProperties, getClassObjects, deleteObjectFromClass } from '../../../assets/js/vectorDatabase.js';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import PropertyModal from './PropertyModal.vue';

DataTable.use(DataTablesCore);

export default {
  name: 'VectorDatabaseClass',
  data() {
    return {
      className: "Database",
      properties: [],
      columns: [],
      data: []
    };
  },
  components: {
    DataTable,
    PropertyModal
  },
  methods: {
    async addRecord() {
      // Open a form popup to add a new record
      this.$refs.propertyModal.showAddForm(this.properties);
    },
    async editRecord(data) {
      // Open a form popup pre-filled with the data to be edited
      this.$refs.propertyModal.showEditForm(this.properties, data);
    },
    async deleteRecord(data) {
      // Confirm before deleting
      if (confirm('Are you sure you want to delete this record?')) {
        await deleteObjectFromClass(this.className, data._additional.id); 
        this.data = this.data.filter(item => item._additional.id !== data._additional.id);
      }
    }
  },
  async mounted() {
    try {
      this.className = decodeURI(this.$route.query.className);
      const properties = await getClassProperties(this.className);
      const objects = await getClassObjects(this.className);
      const data = await objects.data.Get[this.className];

      if (properties && properties[0]) {
        // const dataKeys = Object.keys(data[0]).filter(key => key != "_additional");
        // this.columnOrder = this.columnOrder.filter(key => dataKeys.includes(key));

        // const columns = this.columnOrder.map((key) => ({ 'data': key, 'title': toTitleCase(key) }));

        this.properties = properties.sort(function(a, b){return parseInt(JSON.parse(a.description).position) - parseInt(JSON.parse(b.description).position)});

        this.columns = this.properties.map((obj) => ({ 'data': obj.name, 'title': toTitleCase(obj.name) }));
        this.columns.push({ data: null, defaultContent: '<span class="db-buttons"><i class="fas fa-fw fa-pen edit-btn"></i> <i class="fas fa-fw fa-trash delete-btn"></i></span>', orderable: false });
        this.data = data;

      } else {
        console.error('Data fetch error: Data is undefined or not structured correctly');
      }

      document.querySelector('.add-data-button').addEventListener('click', () => {
              this.addRecord();
            });

    } catch (error) {
      console.error('Data fetch error:', error);
    }

    function toTitleCase(str) {
      return str.replaceAll("_"," ").replaceAll(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }

  }
}
</script>


<style>
@import 'datatables.net-dt';
</style>

<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Case Study Generator</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo">
        </div>
      </div>
      <div id="query-form">

          <h2>Category</h2>
          <div class="tender-category container-columns" style="margin-bottom:20px;">
            <select id="category">
              <option value="Mixed" selected>Mixed</option>
              <option value="Digital">Digital</option>
              <option value="Offline">Offline</option>
            </select>
          </div>

          <h2>Requirements</h2>
          <div class="tender-requirements container-columns" style="margin-bottom:20px;">
            <textarea name="requirements" placeholder="Add the case study requirements here..." class="question-input" id="requirements"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Client details</h2>
          <div class="tender-client container-columns" style="margin-bottom:20px;">
            <textarea name="client" placeholder="Add the client name and contact details here..." class="question-input" id="client"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Background context</h2>
          <div class="tender-background container-columns" style="margin-bottom:20px;">
            <textarea name="background" placeholder="Add any background context about the case study here..." class="question-input" id="background"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Expertise</h2>
          <div class="tender-expertise container-columns" style="margin-bottom:20px;">
            <textarea name="expertise" placeholder="Add details around how we demonstrate our expertise in selected area here..." class="question-input" id="expertise"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Knowledge & insight</h2>
          <div class="tender-knowledge container-columns" style="margin-bottom:20px;">
            <textarea name="knowledge" placeholder="Add details around how we demonstrated audience knowledge & insight here..." class="question-input" id="knowledge"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Innovation & strategic thinking</h2>
          <div class="tender-innovation container-columns" style="margin-bottom:20px;">
            <textarea name="innovation" placeholder="Add details around how we showed our innovation & strategic long term thinking here..." class="question-input" id="innovation"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Account management & reporting</h2>
          <div class="tender-management container-columns" style="margin-bottom:20px;">
            <textarea name="management" placeholder="Share details of how we approached account management & reporting here..." class="question-input" id="management"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Results</h2>
          <div class="tender-results container-columns" style="margin-bottom:20px;">
            <textarea name="results" placeholder="Add the campaign results here..." class="question-input" id="results"
                rows="5" style="width:100%"></textarea>
          </div>
        

        <div class="question-button">
          <button id="question-button" type="submit">Generate</button>
        </div>
      </div>
      <div id="loading" class="loading" style="display: none;">
        <div class="spinner"></div>
      </div>
      <div id="response">
        <!-- Query results will be displayed here -->
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>

<script>
export default {
  name: 'CaseStudyGenerator',
  mounted() {
    import("../../../assets/js/caseStudies.js").then(m => {
      m.caseStudies;
    });
  }
}
</script>

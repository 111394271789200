<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Testing Zone</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo">
        </div>
      </div>
      <div id="query-form">

        <h2>File selector</h2>
          <div class="container-columns" style="margin-bottom:20px;">
            <input type="text" name="file-id" class="file-id-input" id="file-id" style="width: 100px;">
          </div>

        <div class="commentary-button" style="margin-bottom: 10px;">
          <button id="files-button" type="submit">Import file</button>
        </div>

        <div class="commentary-button" style="margin-bottom: 10px;">
          <button id="test-button" type="submit">Run test</button>
        </div>
      </div>
      <div id="loading" class="loading" style="display: none;">
        <div class="spinner"></div>
      </div>
      <div id="results">
        <!-- Query results will be displayed here -->
      </div>
    </div>

  </div>
  <!-- End of Main Content -->
</template>

<script>
export default {
  name: 'TestingZone',
  mounted() {
    import("../../../assets/js/test.js").then(m => {
      m.test;
    });

  }
}
</script>

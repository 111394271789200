<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Tender Machine</h1>
        <div class="container-columns query-form">
          <div class="col-flex-left">
        <select id="tenders" style="min-width:250px">
              <option value="" disabled selected>Select a tender...</option>
              <!-- Tenders will be dynamically populated here -->
            </select>
          </div>
          <div class="col-flex-right">
            <button id="new-tender-btn" class="app-btn"> New tender </button>
          </div></div>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo">
        </div>
      </div>
      <div id="query-form" class="query-form">

        <!-- <h2>Settings</h2> -->
          <div class="tender-settings container-columns" style="justify-content: left; margin-bottom:30px; display:none">
            <!-- Button to open the modal -->
            <button id="initalise-db" style="margin-right: 10px;" disabled> Initialise </button>
  
            <!-- Button to open the modal -->
            <button id="upload-questions-btn" disabled> Upload </button>
          </div>

          <!-- <h2>Category</h2> -->
          <div class="tender-category container-columns" style="margin-bottom:20px; display:none">
            <select id="category">
              <option value="Mixed" selected>Mixed</option>
              <option value="Digital">Digital</option>
              <option value="Offline">Offline</option>
            </select>
          </div>

          <h2>Tender Context</h2>
          <h3 style="font-size: 1rem">Add any additional context about the tender here...</h3>
          <div class="tender-context container-columns" style="margin-bottom:20px;">
            <textarea name="context" placeholder="e.g. Overview of the British Council
We support peace and prosperity by building connections, understanding and trust between people
in the UK and countries worldwide.
We uniquely combine the UK’s deep expertise in arts and culture, education and the English
language, our global presence and relationships in over 100 countries, our unparalleled access to
young people and influencers and our creative sparkle.
We work directly with individuals to help them gain the skills, confidence and connections to
transform their lives and shape a better world in partnership with the UK. We support them to build
networks and explore creative ideas, to learn English, to get a high-quality education and to gain
internationally recognised qualifications.
We work with governments and our partners in the education, English language and cultural sectors,
in the UK and globally. Working together we make a bigger difference, creating benefit for millions of
people all over the world.
We work with people in over 200 countries and territories and are on the ground in more than 100
countries. In 2021–22 we reached 650 million people" class="question-input" id="context"
                rows="5" style="width:100%"></textarea>
          </div>

          <h2>Question</h2>
          <h3 style="font-size: 1rem">Add your tender question here</h3>
          <div class="tender-question container-columns" style="margin-bottom:20px;">
            <textarea name="question" placeholder="e.g. How do you support the health and wellbeing of your teams within the organisation, please provide examples." class="question-input" id="question"
                rows="5" style="width:100%"></textarea>
          </div>
          
          <h2>Word Limit</h2>
          <h3 style="font-size: 1rem">Add the word limit for the answer here</h3>
          <div class="tender-question container-columns" style="margin-bottom:20px;">
            <input type="text" name="word-limit" placeholder="750" class="word-limit-input" id="word-limit" style="width: 100px;">
          </div>

          <h2>Mark Scheme</h2>
          <h3 style="font-size: 1rem">Add the marking criteria for the tender question here</h3>
          <div class="mark-scheme container-columns" style="margin-bottom:20px;">
            <textarea name="question" placeholder="Score between 0 and 10 based on the following criteria:

[Points] Interpretation

[10] Excellent – Overall the response demonstrates that the bidder meets all areas of the requirement and provides all of the areas evidence requested in the level of detail requested. This, therefore, is a detailed excellent response that meets all aspects of the requirement leaving no ambiguity as to whether the bidder can meet the requirement.

[7] Good – Overall the response demonstrates that the bidder meets all areas of the requirement and provides all of the areas of evidence requested, but contains some trivial omissions in relation to the level of detail requested in terms of either the response or the evidence. This, therefore, is a good response that meets all aspects of the requirement with only a trivial level ambiguity due the bidders failure to provide all information at the level of detail requested.

[5] Adequate – Overall the response demonstrates that the bidder meets all areas of the requirement, but not all of the areas of evidence requested have been provided. This, therefore, is an adequate response, but with some limited ambiguity as to whether the bidder can meet the requirement due to the bidder’s failure to provide all of the evidence requested.

[3] Poor – The response does not demonstrate that the bidder meets the requirement in one or more areas. This, therefore, is a poor response with significant ambiguity as to whether the bidder can meet the requirement due to the failure by the bidder to show that it meets one or more areas of the requirement.

[0] Unacceptable – The response is non-compliant with the requirements of the ITT and/or no response has been provided." class="mark-scheme-input" id="mark-scheme"
                rows="5" style="width:100%"></textarea>
          </div>

        <div class="question-button">
          <button id="question-button" type="submit">Tenderize it</button>
        </div>
      </div>
      <div id="loading" class="loading" style="display: none;">
        <div class="spinner"></div>
      </div>
      <div id="results">
        <!-- Query results will be displayed here -->
      </div>
    </div>
  </div>

  <!-- Tender Modal -->
  <div id="tender-modal" class="modal-comms">

<!-- Modal content -->
<div class="modal-content-comms">
  <h2>Add new tender</h2>
  <h3>Client</h3>
  <input type="text" name="new-tender-client" class="question-input" id="new-tender-client" style="width:100%">
  <h3>Tender context</h3>
  <textarea name="new-tender-context" placeholder="Add the additional context about the tender here..." class="question-input" id="new-tender-context"
                rows="5" style="width:100%"></textarea>
  <h3>Mark scheme</h3>
  <textarea name="new-tender-mark-scheme" placeholder="Add the mark scheme for the tender here..." class="question-input" id="new-tender-mark-scheme"
                rows="5" style="width:100%"></textarea>
  <button id="add-tender-context-btn" class="app-btn">Add</button>
  <div class="error-message"></div>
</div>

</div>
  <!-- End of Main Content -->
</template>

<script>
export default {
  name: 'TenderResponse',
  mounted() {
    import("../../../assets/js/tenders.js").then(m => {
      m.tenders;
    });
  }
}
</script>

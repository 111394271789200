<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/">
            <img class="sidebar-img" src="../../assets/img/aip-media.png" />
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0" />

        <!-- Nav Item - Dashboard -->
        <!-- <li class="nav-item active">
            <router-link class="nav-link" to="/" @click.native="closeOpenMenus">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
            </router-link>
        </li> -->

        <!-- Divider -->
        <!-- <hr class="sidebar-divider" /> -->


                <!-- Heading -->
                <!-- <div class="sidebar-heading">
            All Staff
        </div> -->

        <!-- Nav Item - Chat with Pete -->
        <!-- <li class="nav-item">
            <router-link class="nav-link" to="/chat-with-pete" @click.native="closeOpenMenus">
                <i class="fas fa-fw fa-comments"></i>
                <span>Chat with Pete</span>
            </router-link>
        </li> -->

        <!-- Nav Item - Clients Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true"
                aria-controls="collapsePages">
                <i class="fas fa-fw fa-briefcase"></i>
                <span>Clients</span>
            </a>
            <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                <div class="bg-green py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Settings:</h6>
                    <router-link class="collapse-item" to="/campaign-mapping">Campaign Mapping</router-link>
                </div>
            </div>
        </li> -->

                <!-- Divider -->
                <!-- <hr class="sidebar-divider" /> -->



        <!-- Heading -->
        <!-- <div class="sidebar-heading">
            Digital Team
        </div> -->

        <!-- Nav Item - Report Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReport" aria-expanded="true"
                aria-controls="collapseReport">
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Report</span>
            </a>
            <div id="collapseReport" class="collapse" aria-labelledby="headingReport" data-parent="#accordionSidebar">
                <div class="bg-green py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Generate report:</h6>
                    <router-link class="collapse-item" to="/report-commentary">Reporting assistant</router-link>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Monitor Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMonitor"
                aria-expanded="true" aria-controls="collapseMonitor">
                <i class="fas fa-fw fa-clipboard-list"></i>
                <span>Monitor</span>
            </a>
            <div id="collapseMonitor" class="collapse" aria-labelledby="headingMonitor" data-parent="#accordionSidebar">
                <div class="bg-green py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Campaign Spend:</h6>
                    <router-link class="collapse-item" to="/budget-pacing">Budget pacing</router-link>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Optimise Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseOptimise"
                aria-expanded="true" aria-controls="collapseOptimise">
                <i class="fas fa-fw fa-sort-amount-up"></i>
                <span>Optimise</span>
            </a>
            <div id="collapseOptimise" class="collapse" aria-labelledby="headingOptimise" data-parent="#accordionSidebar">
                <div class="bg-green py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Optimise:</h6>
                    <router-link class="collapse-item" to="/insights">Insights</router-link>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Automate Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAutomate"
                aria-expanded="true" aria-controls="collapseAutomate">
                <i class="fas fa-fw fa-hand-sparkles"></i>
                <span>Automate</span>
            </a>
            <div id="collapseAutomate" class="collapse" aria-labelledby="headingAutomate" data-parent="#accordionSidebar">
                <div class="bg-green py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Automations:</h6>
                    <router-link class="collapse-item" to="/signal">Signal</router-link>
                </div>
            </div>
        </li> -->

        <!-- Nav Item - Test Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTest" aria-expanded="true"
                aria-controls="collapseTest">
                <i class="fas fa-fw fa-vial"></i>
                <span>Test</span>
            </a>
            <div id="collapseTest" class="collapse" aria-labelledby="headingTest" data-parent="#accordionSidebar">
                <div class="bg-green py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Testing Plans:</h6>
                    <router-link class="collapse-item" to="/ad-copy-tests">Ad copy testing</router-link>
                </div>
            </div>
        </li> -->

        <!-- Divider -->
        <!-- <hr class="sidebar-divider" /> -->


<!-- Heading -->
<div class="sidebar-heading">
    Marketing
</div>

<!-- Nav Item - Tenders Collapse Menu -->
<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTenders" aria-expanded="true"
        aria-controls="collapseTenders">
        <i class="fas fa-fw fa-briefcase"></i>
        <span>Tenders</span>
    </a>
    <div id="collapseTenders" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-green py-2 collapse-inner rounded">
            <h6 class="collapse-header">Tender Response:</h6>
            <router-link class="collapse-item" to="/tender-response">Answer Question</router-link>
            <router-link class="collapse-item" to="/tender-marking">Score Response</router-link>
            <!-- <router-link class="collapse-item" to="/case-study-generator">Generate Case Study</router-link> -->
        </div>
    </div>
</li>

        <!-- Divider -->
        <!-- <hr class="sidebar-divider d-none d-md-block" /> -->


<!-- Heading -->
<!-- <div class="sidebar-heading">
    Admin
</div> -->

<!-- Nav Item - Tenders Collapse Menu -->
<!-- <li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDatabases" aria-expanded="true"
        aria-controls="collapseDatabases">
        <i class="fas fa-fw fa-briefcase"></i>
        <span>Databases</span>
    </a>
    <div id="collapseDatabases" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-green py-2 collapse-inner rounded">
            <h6 class="collapse-header">Embeddings:</h6>
            <router-link class="collapse-item" to="/vector-database">Vector Database</router-link>
        </div>
    </div>
</li> -->

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block" />


        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>

    </ul>
</template>

<script>
export default {
    name: 'Sidebar',
    mounted() {
        import("../../assets/js/aipete.js").then(m => {
            m.aiPete;
        });
    },
    methods: {
        closeOpenMenus() {
            const openMenus = document.querySelectorAll('.collapse.show');
            openMenus.forEach(menu => {
                menu.classList.remove('show');
            });
            const openArrows = document.querySelectorAll('.nav-link');
            openArrows.forEach(arrow => {
                if (!arrow.classList.contains('collapsed')) {
                    arrow.classList.add('collapsed');
                }
            });
        }
    }
}
</script>

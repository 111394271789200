import { auth } from '../../firebaseInit';

let idToken;

const vectorDatabase = auth.onAuthStateChanged(async (user) => {
  if (user) {
    idToken = await auth.currentUser.getIdToken(true);
  } else {
    console.log("Not signed in");
  }
});

export async function getClasses() {
  try {
    const response = await fetch(process.env.VUE_APP_GET_CLASSES_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      }
    });
    const result = await response.json();
    console.log('Get classes result:', result);
    return result.classes;
  } catch (error) {
    return error.message;
  }
}


export async function createClass(newClass) {
  try {
    const response = await fetch(process.env.VUE_APP_CREATE_CLASS, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newClass),
    });
    const result = await response.json();
    console.log('Create class result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}

export async function deleteClass(classId) {
  try {
    const functionUrl = `${process.env.VUE_APP_DELETE_CLASS}?classId=${classId}`;
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      }
    });
    const result = await response.json();
    console.log('Delete class result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}

export async function getClassObjects(className) {
  try {
    const functionUrl = `${process.env.VUE_APP_GET_CLASS_OBJECTS}?classname=${className}`;
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      }
    });
    const result = await response.json();
    console.log('Get class objects result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}

export async function addObjectToClass(className, objectData) {
  try {
    const functionUrl = process.env.VUE_APP_ADD_OBJECT_TO_CLASS;
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      },
      body: JSON.stringify(className, objectData)
    });
    const result = await response.json();
    console.log('Add object to class result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}

export async function bulkAddObjectsToClass(bulkObjects) {
  try {
    const formData = new FormData();
    formData.append('classname', bulkObjects.classname);
    formData.append('csvFile', bulkObjects.csvFile);

    const functionUrl = process.env.VUE_APP_BULK_ADD_OBJECTS_TO_CLASS;
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      },
      body: JSON.stringify(formData)
    });
    const result = await response.json();
    console.log('Bulk add objects to class result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}

export async function deleteObjectFromClass(className, objectID) {
  try {
    const functionUrl = `${process.env.VUE_APP_DELETE_OBJECT_FROM_CLASS}?className=${className}&objectID=${objectID}`;
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      }
    });
    const result = await response.json();
    console.log('Delete object from class result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}

export async function getClassProperties(className) {
  try {
    const functionUrl = `${process.env.VUE_APP_GET_CLASS_PROPERTIES}?className=${className}`;
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${idToken}`
      }
    });
    const result = await response.json();
    console.log('Get class properties result:', result);
    return result;
  } catch (error) {
    return error.message;
  }
}


export async function bulkUpload(className, file) {
  if (file) {
    let formData = new FormData();
    formData.append('file', file);
    
    try {
      const functionUrl = `${process.env.VUE_APP_READ_EMBEDDINGS}?className=${className}`;
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          Authorization: `bearer ${idToken}`
        },
        body: formData,
      });

      if (response.ok) {
        console.log(response);
      } else {
        console.error('Error uploading file:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
}


export default vectorDatabase;
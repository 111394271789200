<template>
    <div v-if="isFormVisible" class="modal">
        <div class="modal-content">
            <span class="close" @click="isFormVisible = false">&times;</span>
            <form @submit.prevent="handleSubmit">
                <div v-for="property in formProperties" :key="property.data">
                    <label :for="property.data">{{ property.label }}</label>
                    <input v-if="property.format === 'text'" :name="property.data" v-model="formData[property.data]"
                        type="text" />
                    <textarea v-if="property.format === 'longText'" :name="property.data"
                        v-model="formData[property.data]"></textarea>
                    <select v-if="property.format.startsWith('option')" :name="property.data"
                        v-model="formData[property.data]">
                        <option v-for="option in property.format.replace('option(', '').replace(')', '').split(',')"
                            :key="option" :value="option">{{ option }}</option>
                    </select>
                </div>
                <button type="submit">Submit</button>
            </form>
            <input type="file" @change="handleFileUpload" accept=".csv" />
            <button class="bulk-upload-button" @click="uploadFile">Bulk Upload</button>
        </div>
    </div>
</template>
  
  
<script>
import { bulkUpload } from '../../../assets/js/vectorDatabase.js';
export default {
    name: 'PropertyModal',
    data() {
        return {
            isFormVisible: false,
            formData: {},
            formProperties: [],
        };
    },
    methods: {
        showAddForm(properties) {
            this.formData = {}; // Clear form data for adding new record
            this.formProperties = properties.map((obj) => ({ 'data': obj.name, 'label': this.toTitleCase(obj.name), 'format': JSON.parse(obj.description).format }));
            this.isFormVisible = true;
        },
        showEditForm(properties, data) {
            this.formData = { ...data }; // Set form data to the data of the record being edited
            this.formProperties = properties.map((obj) => ({ 'data': obj.name, 'label': this.toTitleCase(obj.name), 'format': JSON.parse(obj.description).format }));
            this.isFormVisible = true;
        },
        async handleSubmit() {
            if (this.formData._additional.id) {
                // Call your update function here
            } else {
                // Call your add function here
            }
            this.isFormVisible = false;
        },
        toTitleCase(str) {
            return str.replaceAll("_"," ").replaceAll(
                /\w\S*/g,
                function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        handleFileUpload(event) {
            this.uploadedFile = event.target.files[0];
            this.className = decodeURI(this.$route.query.className);
        },

        async uploadFile() {
            if (this.uploadedFile) {
                await bulkUpload(this.className, this.uploadedFile)
            }
        }
    }
};
</script>
  
<style>
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-left: auto;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal input,
.modal select,
.modal textarea {
    padding: 8px;
    margin-bottom: 20px;
    border: 2px solid #f200bd;
    border-radius: 4px;
    font-size: 16px;
    background-color: #ffffff;
    color: #333333;
    appearance: none;
    -webkit-appearance: none;
}

.modal textarea {
    width: 75%;
}

.modal input,
.modal select {
    width: 50%;
}
</style>
  
<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Campaign Mapping</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo" />
        </div>
      </div>
      <div class="dashboard-content">
        Coming soon...
      </div>
    </div>
  </div>

  <!-- End of Main Content -->
</template>

<script>
export default {
  name: 'CampaignMapping',
}
</script>

<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <div class="container-content">
      <div class="content-header">
        <h1>Manage Vector Database</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo">
        </div>
      </div>
      <div id="content-main">

        <h2>Databases</h2>
        <div id="database-table" class="row">
          <a class="col-xl-3 col-md-6 mb-4 databaseLink" v-for="(classes, index) in classesList" :key="index" :dbClassName="classes.class" :href="'/vector-database-class?className='+encodeURI(classes.class)">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      {{ classes.class }}
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-database fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>

<script>
import { getClasses } from '../../../assets/js/vectorDatabase.js';

export default {
  name: 'VectorDatabase',
  data() {
    return {
      classesList: []
    };
  },
  async mounted() {
    this.classesList = await getClasses();
  }
}
</script>

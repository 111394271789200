<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">
    <!-- Begin Tab Menu -->
    <div class="conversations-menu">
      <button class="conversations-menu-button" id="newChat" style="border-top-left-radius:5px">+ New Chat</button>
      <button class="conversations-menu-button" id="previousChats">Previous Chats</button>
      <button class="conversations-menu-button" id="currentChat">Current Chat</button>
    </div>
    <!-- End Tab Menu -->

    <!-- Chat conversation window -->
    <div id="chatConversation" class="container-content" style="border-radius: 0px 0px 5px 5px; padding-top: 30px;">
      <div class="content-header">
        <h1>Chat with Pete</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo" />
        </div>
      </div>

      <div id="ChatWindow">
        <div id="ChatContent">
        </div>
        <div id="typingAnimation" className="typing" style="display:none">
          <div className="typing__dot"></div>
          <div className="typing__dot"></div>
          <div className="typing__dot"></div>
        </div>
        <div id="form">
          <form action="/" method="post" class="ChatInput" id="chatForm">
            <div class="input-group">
              <textarea name="message" placeholder="Type your message here..." class="ChatInput-input" id="message"
                rows="1"></textarea>
              <!-- <span class="ChatInput-Buttons"> -->
                <!-- <button type="button" class="ChatInput-btnTemplate" @click="openTemplateModal"><i -->
                    <!-- class="fas fa-robot"></i></button> -->
                <button type="submit" class="ChatInput-btnSend"><i class="fas fa-fw fa-paper-plane"></i></button>
              <!-- </span> -->
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End of chat conversation window -->

    <!-- Previous conversations window -->
    <div id="previousChatConversations" class="container-content"
      style="border-radius: 0px 0px 5px 5px; padding-top: 30px; display:none">
      <div class="content-header">
        <h1>Conversation History</h1>
        <div class="logo-container">
          <img src="../../../assets/img/goat.png" alt="aiPete Logo" class="logo" />
        </div>
      </div>

      <div id="conversations"></div>
    </div>
    <!-- End of previous conversations window -->

    <!-- Edit Modal -->
    <div id="editModal" class="conversation-modal">
      <div class="conversation-modal-content">
        <div class="conversation-modal-header">
          <h3>Edit Conversation</h3>
          <span id="closeEdit" class="close">&times;</span>
        </div>
        <div class="conversation-modal-body">
          <label for="editName">Name:</label>
          <input type="text" id="editName">
          <label for="editDescription">Description:</label>
          <textarea id="editDescription"></textarea>
        </div>
        <div class="conversation-modal-footer">
          <button id="updateConversation">Update</button>
        </div>
      </div>
    </div>


    <!-- Delete Modal -->
    <div id="deleteModal" class="conversation-modal">
      <div class="conversation-modal-content">
        <div class="conversation-modal-header">
          <h3>Delete Conversation</h3>
          <span id="closeDelete" class="close">&times;</span>
        </div>
        <div class="conversation-modal-body">
          <div id="deleteTitle">Are you sure you want to delete the conversation? This cannot be undone.</div>
          <div id="deleteName">Name:</div>
          <div id="deleteDescription">Description:</div>
        </div>
        <div class="conversation-modal-footer">
          <button id="deleteConversation">Delete</button>
        </div>
      </div>
    </div>
  </div>
  <ChatTemplateModal ref="chatTemplateModal" />

  <!-- End of Main Content -->
</template>

<script>
import ChatTemplateModal from './ChatTemplateModal.vue';

export default {
  name: 'ChatWithPete',
  components: {
    ChatTemplateModal
  },
  methods: {
    openTemplateModal() {
      this.isTemplateModalOpen = true;
    },
    closeTemplateModal() {
      this.isTemplateModalOpen = false;
    },
    startConversation(template) {
      // logic to start a conversation based on the selected template
    },
    mounted() {
      import("../../../assets/js/chatWithPete.js").then(m => {
        m.chatWithPete;
      });
    }
  }
}
</script>
<template>
  <div>
    <div v-if="currentUser">
    <!-- Page Wrapper -->
    <div id="wrapper">

      <!-- Sidebar -->
      <Sidebar />
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">

          <!-- Topbar -->
          <Topbar />
          <!-- End of Topbar -->

          <router-view />

          <!-- Footer -->
          <footer class="sticky-footer">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Anything is Possible {{currentYear}}</span>
              </div>
            </div>
          </footer>
          <!-- End of Footer -->

      </div>
      <!-- End of Content Wrapper -->

      <!-- Scroll to Top Button-->
      <a class="scroll-to-top rounded" href="#page-top">
        <i class="fas fa-angle-up"></i>
      </a>
    </div>
    <!-- End of Page Wrapper -->
  </div>
  <div v-else>
      <!-- When no user is authenticated -->
      <router-view />
    </div>
  </div>
</template>
  
<script>
import { auth, onAuthStateChanged } from './firebaseInit';
import Sidebar from './components/navigation/Sidebar.vue'
import Topbar from './components/navigation/Topbar.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    Topbar
  },
  data() {
    return {
      currentUser: null,
      currentYear: /\d{4}/.exec(Date())[0]
    };
  },
  created() {
    onAuthStateChanged(auth, user => {
      this.currentUser = user;
    });
  }
}
</script>
import { createRouter, createWebHistory } from 'vue-router';
import { auth } from './firebaseInit';
import Dashboard from './components/pages/Dashboard.vue';
import SignIn from './components/pages/SignIn.vue';
import Blank from './components/pages/Blank.vue';
import AdCopyTests from './components/pages/digital/AdCopyTests.vue';
import BudgetPacing from './components/pages/digital/BudgetPacing.vue';
import Insights from './components/pages/digital/Insights.vue';
import ReportCommentary from './components/pages/digital/ReportCommentary.vue';
import Signal from './components/pages/digital/Signal.vue';
import CampaignMapping from './components/pages/allstaff/CampaignMapping.vue';
import ChatWithPete from './components/pages/allstaff/ChatWithPete.vue';
import TenderResponse from './components/pages/marketing/TenderResponse.vue';
import TenderMarking from './components/pages/marketing/TenderMarking.vue';
import CaseStudyGenerator from './components/pages/marketing/CaseStudyGenerator.vue';
import VectorDatabase from './components/pages/admin/VectorDatabase.vue';
import VectorDatabaseClass from './components/pages/admin/VectorDatabaseClass.vue';
import TestingZone from './components/pages/allstaff/TestingZone.vue';

const routes = [
  {
    path: '/',
    redirect: '/tender-response',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ad-copy-tests',
    name: 'ad copy tests',
    component: AdCopyTests,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/budget-pacing',
    name: 'budget pacing',
    component: BudgetPacing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/insights',
    name: 'insights',
    component: Insights,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report-commentary',
    name: 'report commentary',
    component: ReportCommentary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/signal',
    name: 'signal',
    component: Signal,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/campaign-mapping',
    name: 'campaign mapping',
    component: CampaignMapping,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat-with-pete',
    name: 'chat with pete',
    component: ChatWithPete,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/tender-response',
    name: 'tender response',
    component: TenderResponse,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tender-marking',
    name: 'mark tender response',
    component: TenderMarking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/case-study-generator',
    name: 'case study generator',
    component: CaseStudyGenerator,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/vector-database',
    name: 'vector database',
    component: VectorDatabase,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vector-database-class',
    name: 'vector database class',
    component: VectorDatabaseClass,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/testing-zone',
    name: 'testing zone',
    component: TestingZone,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && (!currentUser || !currentUser.email.endsWith('@aip.media'))) {
    next('/signin');
  } else {
    next();
  }
});

export default router;
